import { authPost } from "../../common/redux/ApiClient";

const baseUrl = "http://35.240.204.237/ok99memberApi/api";

export function fetchSlotPageUI(params) {
  return authPost(`${baseUrl}/MemberPc1/PcSlotPageUI`, params);
}

export function fetchSlotGameList(params) {
  return authPost(`${baseUrl}/MemberPc1/PcSlotGameList`, params);
}

export function companyPlayGame(params) {
  return authPost(`${baseUrl}/Member3/CompanyPlayGame`, params);
}
