import { authFormPost, authPost, doPost } from "../../common/redux/ApiClient";

const baseUrl = "http://35.240.204.237/ok99memberApi/api";

// start : profile
export function fetchProfile(params) {
  return authPost(`${baseUrl}/MemberPc1/PcProfile`, params);
}

export function changePassword(params) {
  return authPost(`${baseUrl}/Member2/ChangePassword`, params);
}

export function changeLanguage(params) {
  return authPost(`${baseUrl}/Member2/ChangeLanguage`, params);
}

export function fetchCountryBankList(params) {
  return authPost(`${baseUrl}/Member3/CountryBankList`, params);
}

export function addBankAccount(params) {
  return authPost(`${baseUrl}/Member2/AddBankAccount`, params);
}

export function removeBankAccount(params) {
  return authPost(`${baseUrl}/Member2/RemoveBankAccount`, params);
}
// end : profile

// start : game transfer
export function fetchGameCompanyTransferUI() {
  return authPost(`${baseUrl}/Member3/GameCompanyTransferUI`, {});
}

export function fetchCompanyGameBalance(queryParams) {
  return authPost(`${baseUrl}/Member3/CompanyGameBalance`, queryParams);
}

export function companyGameTopup(queryParams) {
  return authPost(`${baseUrl}/Member3/CompanyGameTopup`, queryParams);
}

export function transferAllGameBackWallet() {
  return authPost(`${baseUrl}/Member3/TransferAllGameBackWallet`, {});
}

export function companyGameWithdraw(queryParams) {
  return authPost(`${baseUrl}/Member3/CompanyGameWithdraw`, queryParams);
}

export function switchCurrencyCode(queryParams) {
  return authPost(`${baseUrl}/Member3/SwitchCurrencyCode`, queryParams);
}

export const fetchCurrencyList = (queryParams) => {
  return doPost(`${baseUrl}/Member1/CurrencyList`, queryParams);
};

// end : game transfer

// start : transaction report

export const fetchTransactionReport = (queryParams) => {
  return authPost(`${baseUrl}/Member4/MemberReport`, queryParams);
};

// end : transaction report

// start : transaction report

export const fetchGameReport = (queryParams) => {
  return authPost(`${baseUrl}/Member7/GameReport`, queryParams);
};

// end : transaction report

// start : transaction report

export const fetchDownline = () => {
  return authPost(`${baseUrl}/Member7/DownlineList`, {});
};

// end : transaction report

// start : withdraw

export const fetchWithdrawUI = () => {
  return authPost(`${baseUrl}/Member4/WithdrawUI`, {});
};

export const withdrawRequest = (queryParams) => {
  return authPost(`${baseUrl}/Member5/WithdrawRequest`, queryParams);
};

export const fetchWithdrawList = (queryParams) => {
  return authPost(`${baseUrl}/Member5/WithdrawList`, queryParams);
};

export const withdrawCancel = (queryParams) => {
  return authPost(`${baseUrl}/Member5/WithdrawCancel`, queryParams);
};

// end : withdraw

// start : deposit

export const fetchDepositWalletUI = () => {
  return authPost(`${baseUrl}/Member4/TopupWalletUI`, {});
};

export const fetchAtmDepositUI = () => {
  return authPost(`${baseUrl}/Member5/TopupManualUI`, {});
};

export const atmDepositRequest = (queryParams) => {
  return authFormPost(
    `${window.location.protocol}//media.279rc.com/api/deposit.php`,
    queryParams
  );
};

export const fetchAtmDepositList = (queryParams) => {
  return authPost(`${baseUrl}/Member5/TopupManualList`, queryParams);
};

export const atmDepositCancel = (queryParams) => {
  return authPost(`${baseUrl}/Member5/TopupCancel`, queryParams);
};

export const fetchGatewayDepositUI = () => {
  return authPost(`${baseUrl}/Member6/TopupGatewayUI`, {});
};

export const fetchTelcoDepositUI = () => {
  return authPost(`${baseUrl}/Member7/TopupTelcoUI`, {});
};

export const telcoDepositRequest = (queryParams) => {
  return authPost(`${baseUrl}/Member7/TelcoCreateBill`, queryParams);
};

export const gatewayDepositRequest = (queryParams) => {
  return authPost(`${baseUrl}/Member6/GatewayCreateBill`, queryParams);
};

export const fetchGatewayDepositList = (queryParams) => {
  return authPost(`${baseUrl}/Member6/TopupGatewayList`, queryParams);
};

export const fetchTelcoDepositList = (queryParams) => {
  return authPost(`${baseUrl}/Member8/TopupTelcoList`, queryParams);
};

// end : deposit

// start : member Transfer

export const memTransferMem = (queryParams) => {
  return authPost(`${baseUrl}/Member3/MemTransferMem`, queryParams);
};

// end :  member Transfer

// start : point shop

export const fetchPointShops = (queryParams) => {
  return authPost(`${baseUrl}/Member4/PointShops`, queryParams);
};

export const shopPointsToBalance = (queryParams) => {
  return authPost(`${baseUrl}/Member5/ShopPointsToBalance`, queryParams);
};

// end : point shop

// start : exchange wallet

export const fetchMultiWalletInfo = () => {
  return authPost(`${baseUrl}/Member2/MultiWalletInfo`, {});
};

export const fetchMultiWalletRateList = (queryParams) => {
  return authPost(`${baseUrl}/Member4/MultiWalletRateList`, queryParams);
};

export const exchangeCurrency = (queryParams) => {
  return authPost(`${baseUrl}/Member4/ExchangeCurrency`, queryParams);
};

// end : exchange wallet

// start : bonus
export const fetchBonusList = (queryParams) => {
  return authPost(`${baseUrl}/Member4/BonusList`, queryParams);
};

export const bonusToBalance = (queryParams) => {
  return authPost(`${baseUrl}/Member4/BonusToBalance`, queryParams);
};
// end : bonus

// start : bonus

export const fetchAgentUsername = () => {
  return authPost(`${baseUrl}/Member8/RedirectToAgent`, {});
};
// end : bonus
