import { authPost } from "../../common/redux/ApiClient";

const baseUrl = "http://35.240.204.237/ok99memberApi/api";

export function fetchAppGamesPageUI(params) {
  return authPost(`${baseUrl}/MemberPc2/PcAppGamePageUI`, params);
}

export function createAppGameID(params) {
  return authPost(`${baseUrl}/Member4/CreateAppGameID`, params);
}

export function resetAppGamePassword(params) {
  return authPost(`${baseUrl}/Member4/ResetAppGamePassword`, params);
}
